.ingestion-container {
    margin-top: 80px;
}

.ingestion-container-header {
    text-align: justify;
    border-bottom: 1px dashed skyblue;
}
.ingestion-file-upload-error {
    color: red;
}