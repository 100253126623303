.deletion-container {
    margin-top: 80px;
}

.deletion-container-header {
    text-align: justify;
    border-bottom: 1px dashed skyblue;
}

.deletion-list-item {
    border-bottom: 1px dashed gray;
}

.deletion-list-item:hover {
    background-color: aliceblue;
}
